import {Controller} from '@hotwired/stimulus'

// Connects to data-controller="home-visit-booking-date-controller"
export default class extends Controller {
  static targets = ['date', 'radioButton']

  connect() {}

  onDateChange(event) {
    const date = this.dateTarget.value;
    const radioButtons = this.radioButtonTargets;

    if (!date) {
      radioButtons.forEach(button => {
        button.required = false;
      });
    } else {
      radioButtons.forEach(button => {
        button.required = true;
      });
    }
  }

  onRadioButtonChange(event) {
    const dateField = this.dateTarget;
    const radioButton = event.target;

    if (radioButton.checked) {
      dateField.setAttribute('required', 'required');
    } else {
      dateField.removeAttribute('required');
    }
  }
}
